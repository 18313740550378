
$(function(){
    // スムーススクロール
    $('a[href^="#"]').click(function(){
        var main_headerHeight = $('header').outerHeight();
        var sp_headerHeight = $('.header-humberger').outerHeight();
        var scroll_speed = 500;
        var scroll_href= $(this).attr("href");
        var scroll_target = $(scroll_href == "#" || scroll_href == "" ? 'html' : scroll_href);
        console.log(scroll_target);

        if (window.matchMedia('(max-width: 767px)').matches) {
            bodyFixedOff();
            $("#js-menu").fadeOut(200);
            $(".humberger-fade-item").removeClass('action');
            $("#js-menu-open").removeClass('open');

            var sp_scroll_position = scroll_target.offset().top - sp_headerHeight;
            $("html, body").animate({scrollTop:sp_scroll_position}, scroll_speed, "swing");
            return false;
        }else {
            let speed = 500;
            let href= $(this).attr("href");
            let target = $(href == "#" || href == "" ? 'html' : href);
            let position = target.offset().top - main_headerHeight;
            $("html, body").animate({scrollTop:position}, speed, "swing");
            return false;
            
        }
    });


    //別ページからのリンク
    var headerHight = $('header').outerHeight();//ヘッダの高さ
    var sp_headerHight = $('.header-humberger').outerHeight();//ヘッダの高さ
    var str = location.href ;
    var cut_str = "#";
    var index = str.indexOf(cut_str);
    var href = str.slice(index);
    var target = href;
        if(document.URL.match("#specialists__")) {
            $('body,html').stop().scrollTop(0);

            if (window.matchMedia('(max-width: 767px)').matches) {
                var sp_position = $(target).offset().top - (sp_headerHight + sp_headerHight);
                $("html, body").stop().animate({scrollTop:sp_position},500);
                return false;
            }else {
                var position = $(target).offset().top - (headerHight + headerHight);
                $("html, body").stop().animate({scrollTop:position},500);
                return false;
            }
        }
        window.addEventListener('load', (event) => {

            if(document.URL.match("#collector")){
                var collector_position = $("#collector").offset().top - headerHight;
                var sp_collector_position = $("#collector").offset().top - sp_headerHight;
                $('body,html').stop().scrollTop(0);

                if (window.matchMedia('(max-width: 767px)').matches) {
                    $("html, body").stop().animate({scrollTop:sp_collector_position},500);
                    return false;
                }else {
                    $("html, body").stop().animate({scrollTop:collector_position},500);
                    return false;
                }
            }else if(document.URL.match("#petit")){
                var petit_position = $("#petit").offset().top - headerHight;
                var sp_petit_position = $("#petit").offset().top - sp_headerHight;
                $('body,html').stop().scrollTop(0);

                if (window.matchMedia('(max-width: 767px)').matches) {
                    $("html, body").stop().animate({scrollTop:sp_petit_position},500);
                    return false;
                }else {
                    $("html, body").stop().animate({scrollTop:petit_position},500);
                    return false;
                }
            }else if(document.URL.match("#program")){
                var program_position = $("#program").offset().top - headerHight;
                var sp_program_position = $("#program").offset().top - sp_headerHight;
                $('body,html').stop().scrollTop(0);

                if (window.matchMedia('(max-width: 767px)').matches) {
                    $("html, body").stop().animate({scrollTop:sp_program_position},500);
                    return false;
                }else {
                    $("html, body").stop().animate({scrollTop:program_position},500);
                    return false;
                }
            }else if(document.URL.match("#artists__")){
                $('body,html').stop().scrollTop(0);

                if (window.matchMedia('(max-width: 767px)').matches) {
                    var sp_position = $(target).offset().top - sp_headerHight;
                    $("html, body").stop().animate({scrollTop:sp_position},500);
                    return false;
                }else {
                    var position = $(target).offset().top - headerHight;
                    $("html, body").stop().animate({scrollTop:position},500);
                    return false;
                }
            }
        });

    //変数の設定
    var $body = $('body');

    //スクロール量を保存
    var scrollTop;

    //スクロールを固定
    function bodyFixedOn() {
        scrollTop = $(window).scrollTop();

        $body.css({
            position: 'fixed',
            top: -scrollTop,
            'overflow-y': 'hidden'
        });
    }

    //スクロールの固定を解除
    function bodyFixedOff() {
        $body.css({
            position: '',
            top: '',
            'overflow-y': ''
        });
        
        $(window).scrollTop(scrollTop);
    }

    $("#js-menu-open").click(function(){
        $(this).toggleClass('open');

        if($(this).hasClass('open')){
            bodyFixedOn();
            $("#js-menu").fadeIn(400);
            $(".humberger-fade-item").addClass('action');
        }else{
            bodyFixedOff();
            $("#js-menu").fadeOut(200);
            $(".humberger-fade-item").removeClass('action');
        }
    });

    //スクロールしてトップへ戻る
    $("#pagetop").on('click',function () {
        $('body,html').animate({
        scrollTop: 0
        }, 500);
    });
});


